import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'reakit'
import styled from 'styled-components'

import { palette as colours } from '../theme/theme'
import crossIcon from '!raw-loader!../assets/cross-icon--white.svg'
import pauseIcon from '!raw-loader!../assets/pause-icon--white.svg'
import playIcon from '!raw-loader!../assets/play-icon--white.svg'
import SVG from './SVG'
import useInterval from '../utils/useInterval'

const crossIconProps = {
  width: '32px',
  height: '32px',
  svg: crossIcon,
  title: 'Stop audio',
}

const pauseIconProps = {
  width: '25px',
  height: '39px',
  svg: pauseIcon,
  title: 'Pause',
}

const playIconProps = {
  width: '25px',
  height: '39px',
  svg: playIcon,
  title: 'Play in language',
}

const barHeight = '62px'

const Container = styled.div`
  bottom: 0;
  height: ${barHeight};
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  border-top: 4px solid white;
`
const Spacer = styled.div`
  height: ${barHeight};
  width: 100%;
`
const Wrapper = styled.div`
  align-items: center;
  background: ${colours.ipGold};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: ${barHeight};
  margin: 0 auto;
  max-width: 768px;
  padding: 0.3rem;
  position: relative;
  width: 100%;
`
const StyledIconButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  flex: 0 0 70px;
  height: 100%;
  justify-content: center;
  max-height: 100%;
  padding: 0 1rem;
  width: 70px;
`
const TrackbarWrapper = styled.div`
  flex: 1 1 auto;
  padding: 0 1rem;
`
const Track = `
  background: ${colours.white};
  height: 7px;
  position: relative;
  border-radius: 0;
`
const Nub = `
  -webkit-appearance: none;
  background: ${colours.ipPurple};
  border-radius: 0;
  border: 0;
  height: 26px;
  margin-top: -14px;
  width: 8px;
`
const Trackbar = styled.input`
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;

  &::-webkit-slider-runnable-track {
    ${Track}
  }
  &::-moz-range-track {
    ${Track}
  }
  &::-ms-track {
    ${Track}
  }
  &::-ms-fill-upper {
    ${Track}
  }
  &::-ms-fill-lower {
    ${Track}
  }

  &::-webkit-slider-thumb {
    ${Nub}
    transform: translate(0, 5px);
  }
  &::-moz-range-thumb {
    ${Nub}
    transform: translate(0, 1px);
  }
  &::-ms-thumb {
    ${Nub}
  }
`

const AudioBar = ({ path, setPath, navState }) => {
  const [isPlaying, setIsPlaying] = useState(null)
  const [currentTime, setCurrentTime] = useState(0)
  const audioElement = useRef(null)

  const handlePlay = event => {
    audioElement.current.play()
    setIsPlaying(true)
  }

  const handlePause = event => {
    audioElement.current.pause()
    setIsPlaying(false)
  }

  const handleClose = event => {
    setPath(null)
  }

  const handleProgress = event => {
    if (!audioElement.current) {
      return
    }

    audioElement.current.currentTime = event.currentTarget.value
    setCurrentTime(event.currentTarget.value)

    if (audioElement.current.currentTime >= audioElement.current.duration) {
      handlePause()
    }
  }

  const getDuration = () => {
    if (!audioElement.current || !audioElement.current.duration) {
      return 0
    }
    return audioElement.current.duration
  }

  const updateCurrentTime = () => {
    if (!audioElement.current) {
      return
    }

    setCurrentTime(audioElement.current.currentTime)

    if (audioElement.current.currentTime >= audioElement.current.duration) {
      handlePause()
    }
  }

  useEffect(() => {
    if (audioElement.current) handlePlay()
  }, [path])

  useEffect(() => {
    // pause if the navigation is opened, as the AudioBar is covered
    if (audioElement.current && isPlaying && navState) handlePause()
  }, [isPlaying, navState])

  useInterval(updateCurrentTime, path ? 200 : null)

  if (!path) {
    return null
  }

  return (
    <>
      <Spacer />
      <Container>
        <audio ref={audioElement} src={path} />
        <Wrapper>
          {isPlaying ? (
            <StyledIconButton
              aria-label="Pause audio"
              palette="secondary"
              onClick={handlePause}
            >
              <SVG {...pauseIconProps} />
            </StyledIconButton>
          ) : (
            <StyledIconButton
              aria-label="Play audio"
              palette="secondary"
              onClick={handlePlay}
            >
              <SVG {...playIconProps} />
            </StyledIconButton>
          )}
          <TrackbarWrapper>
            <Trackbar
              name="Audio progress"
              max={getDuration()}
              min="0"
              onChange={handleProgress}
              step="0.1"
              type="range"
              value={currentTime}
              aria-valuemin="0"
              aria-valuemax={getDuration()}
              aria-valuenow={currentTime}
            />
          </TrackbarWrapper>
          <StyledIconButton
            aria-label="Close audio"
            palette="secondary"
            onClick={handleClose}
          >
            <SVG {...crossIconProps} />
          </StyledIconButton>
        </Wrapper>
      </Container>
    </>
  )
}

export default AudioBar
