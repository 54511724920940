import React from 'react'
import { Box } from 'reakit'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

const Component = ({ width, maxWidth, maxHeight, ...props }) => (
  <Box as="span" {...props} dangerouslySetInnerHTML={{ __html: props.svg }} />
)

/**
 * Display an SVG, setting `fill` to `currentColor`
 *
 * If the image is very small, instruct webpack to import it as a file
 * rather than a `data:image/svg` URL using the `!raw-loader!` prefix
 * (see below).
 *
 * The `width`, `maxWidth`, and `maxHeight` props are passed to the SVG tag,
 * whilst other props are rendered on the containing `span`.
 *
 * ```jsx
 * import tinyIcon from '!raw-loader!../assets/tiny-icon.svg'
 *
 * <SVG svg={iconClose} alt="Alt text" />
 * <SVG svg={iconClose} alt="Alt text" maxWidth="2rem" />
 * <SVG svg={iconClose} alt="Alt text" style={{ ... }} />
 * ```
 */
const SVG = styled(Component)`
  svg {
    height: auto;
    width: ${ifProp('width', prop('width'), 'initial')};
    max-width: ${ifProp('maxWidth', prop('maxWidth'), '100%')};
    max-height: ${ifProp('maxHeight', prop('maxHeight'), '100%')};
    fill: currentColor !important;
  }
`

export default SVG
