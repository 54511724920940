import defaultTheme from 'reakit-theme-default'
import { palette as p } from 'styled-tools'
import * as components from './components'

export const spacing = {
  desktop: '1024px',
}

// https://github.com/diegohaz/styled-tools/blob/master/README.md#palette
export const palette = {
  // https://coolors.co/f47721-008fb4-82c341-414142-ffffff
  ipOrange: '#f47721',
  ipBlue: '#008fb4',
  ipGreen: '#82c341',
  ipSmoke: '#414142',
  ipYellow: '#FEF7E4',
  ipYellowDark: '#F7D880',
  ipGold: '#EBAC44',
  ipPurple: '#632376',

  white: '#ffffff',
  whiteText: p('black'),

  black: '#212121',
  blackText: p('white'),

  primary: [p('ipPurple'), p('ipOrange'), p('ipGreen')],
  primaryText: [p('white'), p('white'), p('black')],

  inverse: [p('ipYellowDark'), p('white'), p('white')],
  inverseText: [p('ipPurple'), p('ipOrange'), p('ipGreen')],

  // secondary: ['red', 'yellow', 'blue'],
  // secondaryText: [p("white"), p("black"), p("white")],

  // https://coolors.co/f44336-ef5350-e57373-ef9a9a-ffcdd2
  danger: ['#f44336', '#ef5350', '#e57373', '#ef9a9a', '#ffcdd2'],
  dangerText: [p('white'), p('white'), p('black'), p('black'), p('black')],

  // https://coolors.co/ffdd32-ffe566-ffeb8c-fff2b2-fff8d8
  alert: ['#ffdd32', '#ffe566', '#ffeb8c', '#fff2b2', '#fff8d8'],
  alertText: [p('black'), p('black'), p('black'), p('black'), p('black')],

  // https://coolors.co/4caf50-66bb6a-81c784-a5d6a7-c8e6c9
  success: ['#4caf50', '#66bb6a', '#81c784', '#a5d6a7', '#c8e6c9'],
  successText: [p('white'), p('white'), p('white'), p('black'), p('black')],

  grayscale: [
    p('black'),
    '#414141',
    '#616161',
    '#9e9e9e',
    '#bdbdbd',
    '#e0e0e0',
    '#f5f5f5',
    p('white'),
  ],

  grayscaleText: [
    p('white'),
    p('white'),
    p('white'),
    p('black'),
    p('black'),
    p('black'),
    p('black'),
    p('black'),
  ],

  background: [
    p('grayscale', -5),
    p('grayscale', -4),
    p('grayscale', -3),
    p('grayscale', -2),
    p('grayscale', -1),
  ],
  backgroundText: p('black'),

  shadow: [
    'rgba(0, 0, 0, 0.9)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.3)',
    'rgba(0, 0, 0, 0.15)',
    'rgba(0, 0, 0, 0.075)',
  ],

  diaphanous: [
    'rgba(255, 255, 255, 0.9)',
    'rgba(255, 255, 255, 0.7)',
    'rgba(255, 255, 255, 0.5)',
    'rgba(255, 255, 255, 0.3)',
    'rgba(255, 255, 255, 0.15)',
    'rgba(255, 255, 255, 0.075)',
  ],

  shadowText: [
    p('white'),
    p('white'),
    p('white'),
    p('black'),
    p('black'),
    p('black'),
  ],

  transparent: 'transparent',
  transparentText: p('black'),

  border: p('shadow', -2),
}

export default {
  ...defaultTheme,
  palette,
  spacing,
  ...components,
}
