import React, { useState } from 'react'
import { Provider } from 'reakit'
import theme from '../theme/theme'
import AudioBar from './AudioBar'
import AudioBarContext from './AudioBarContext'

const AudioBarWrapper = ({ children }) => {
  const [path, setPath] = useState(null)
  const [navState, setNavState] = useState(null)

  return (
    <Provider theme={theme}>
      <AudioBarContext.Provider
        value={{
          path,
          setPath,
          setNavState,
        }}
      >
        {children}
        <AudioBar path={path} setPath={setPath} navState={navState} />
      </AudioBarContext.Provider>
    </Provider>
  )
}

export default AudioBarWrapper
